
import { ReviewCardProps } from '../../molecules/ReviewCard'
import { TrustedShopReview } from '../../repositories/trustedShop'

/**
 * Translates TrustedShop reviews response data to ReviewsTeaser reviews
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 * @param items
 */
export const translateTrustedReviewToReviewsTeaser = (
  items: Array<TrustedShopReview>
): Array<ReviewCardProps> => {
  return items.map((item) => {
    return {
      id: item.id,
      content: item.comment,
      createdAt: item.submittedAt.slice(0, 10),
      rating: item.rating
    }
  })
}
