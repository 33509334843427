







import { Component } from 'vue-property-decorator'

import { ReviewsTeaser } from '../../../front/shared/organisms/ReviewsTeaser'

import { AbstractModuleUi } from '../../abstract/ui'

import { ReviewsTeaserModule } from '../ReviewsTeaser.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ReviewsTeaserModuleUi>({
  name: 'ReviewsTeaserModuleUi',
  components: {
    ReviewsTeaser
  }
})
export class ReviewsTeaserModuleUi extends AbstractModuleUi<ReviewsTeaserModule> {
}
export default ReviewsTeaserModuleUi
