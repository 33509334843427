













































import { Component, Inject as VueInject, Prop, Vue } from 'vue-property-decorator'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../support'

import { ReviewCardProps } from './ReviewCard.contracts'
import { DEFAULT_CONTENT_CROP } from './ReviewCard.config'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ReviewCard>({
  name: 'ReviewCard',
  created () {
    this.loadContent()
  }
})
export class ReviewCard extends Vue {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: Object, required: false })
  public readonly author?: ReviewCardProps['author']

  @Prop({ type: String, required: false })
  public readonly content?: ReviewCardProps['content']

  @Prop({ type: String, required: false })
  public readonly createdAt?: ReviewCardProps['createdAt']

  @Prop({ type: Array, required: false })
  public readonly customClass?: ReviewCardProps['customClass']

  @Prop({ type: String, required: false })
  public readonly id?: ReviewCardProps['id']

  @Prop({ type: String, required: false })
  public readonly productName?: ReviewCardProps['productName']

  @Prop({ type: String, required: false })
  public readonly productSku?: ReviewCardProps['productSku']

  @Prop({ type: Number, required: false })
  public readonly rating?: ReviewCardProps['rating']

  @Prop({ type: Object, required: false })
  public readonly summary?: ReviewCardProps['summary']

  @Prop({ type: String, required: false })
  public readonly title?: ReviewCardProps['title']

  public reviewContent: ReviewCardProps['content'] | null = null
  public shouldToggle: boolean = false
  public isOpen: boolean = false

  public get croppedContent (): string | null | undefined {
    return this.reviewContent
  }

  public toggleReview (): void {
    this.isOpen = !this.isOpen

    if (this.isOpen) {
      this.reviewContent = this.content
    } else {
      // Note! This ignore stetement won't product error because toggleReview is a method
      // triggered by the element that won't be rendered when this.content does not exist.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.reviewContent = `${this.content!.slice(0, DEFAULT_CONTENT_CROP)}...`
    }
  }

  protected loadContent (): void {
    if (!this.content) {
      return
    }

    if (this.content.length < DEFAULT_CONTENT_CROP) {
      this.reviewContent = this.content
    } else {
      this.reviewContent = `${this.content.slice(0, DEFAULT_CONTENT_CROP)}...`
      this.shouldToggle = true
    }
  }
}
export default ReviewCard
